<template>
  <a-modal
      v-drag-modal
      :title="title"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :maskClosable="false"
      :width="1200"
      @cancel="visible = false"
  >
    <div v-if="auditList.length>0" class="list-div">
      <a-timeline>
        <a-timeline-item v-for="item in auditList" :key="item.id">
          <a-tag :color="item.passFlag | dictName('postStatus', 'color')" style="cursor: pointer">{{
              item.passFlag | dictName("postStatus")
            }}
          </a-tag>
          <span>{{ item.auditUserName }}</span>
          <span style="padding:0 10px;">{{ item.auditTime }}</span>
          <span>{{ item.auditRemark }}</span>
        </a-timeline-item>
      </a-timeline>
    </div>
    <div class="input-text">
      <a-textarea
          v-model.trim="authRemark"
          placeholder="请输入不通过的原因"
          :auto-size="{ minRows: 3, maxRows: 5 }"
      />
    </div>
    <div>
      <div style="padding-top:10px;color:#FF9900;">审核不通过常见原因（点击选择，可以多选）：</div>
      <a-tag color="red" v-for="item in reasonList" :key="item" style="cursor: pointer;margin:5px"
             @click="addReason(item)">
        {{ item }}
      </a-tag>
      <a-tag color="red" style="cursor: pointer;margin:5px;white-space: inherit;" @click="addReason('contactService')">
        <span>
        尊敬的打工人，您好！我是诚信点评宣传组的志愿者，您之前发布的帖子富有代表性，为了展示给更多短视频观众，我们希望能够通过电话采访您。
        如果您同意，请您在收到此消息后联系在线客服。我作为志愿者有自己的工作安排，可能会在晚上或周末联系您，
        在此，我们对您的理解和支持表示衷心感谢。
          </span>
      </a-tag>
    </div>

    <div style="margin-top:10px">
      <span>自定义点赞次数：</span>
      <a-input-number v-model="prizeTimes" :min="0" style="width:100px"/>
      <a-button type="primary" @click="randomPrizeTimes()" size="small" style="margin-left: 10px;">
        随机数（5-200，0表示关闭自动点赞）
      </a-button>
    </div>
    <div style="margin-top:10px;display: flex">
      <div style="flex:1;">
        <span>自定义评论：</span>
        <a-textarea v-model="comments" style="height:200px;" placeholder="每行表示一个独立评论，后台随机分配用户"></a-textarea>
      </div>

<!--      <div style="width:400px;margin-left:20px;">-->
<!--        <span>常用评论：</span>-->
<!--        <div style="">-->
<!--          <a-tag color="red" v-for="item in reasonList" :key="item" style="cursor: pointer;margin:5px"-->
<!--                 @click="addComment(item)">-->
<!--            {{ item }}-->
<!--          </a-tag>-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <template slot="footer">
      <div class="modal-btn">
        <!--        <a-button type="primary" :loading="confirmLoading" @click="handleOk(1,true)" style="margin-left: 40px;">-->
        <!--          修改帖子-->
        <!--        </a-button>-->

        <a-button type="success" :loading="confirmLoading" @click="handleOk(1,true)">
          通过并推荐
        </a-button>

        <a-button type="success" :loading="confirmLoading" @click="handleOk(1)" style="margin-left: 40px;">
          通过
        </a-button>
        <a-button type="danger" :loading="confirmLoading" @click="handleOk(-1)" style="margin-left: 40px;">
          不通过
        </a-button>

        <a-button type="default" :loading="confirmLoading" @click="visible=false" style="margin-left:100px;">
          取消
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
export default {
  name: "PostAuditModal",
  data() {
    return {
      visible: false,
      title: "",
      confirmLoading: false,
      authRemark: "",
      prizeTimes: 0,
      comments: "",
      auditList: [],
      record: {}
    };
  },
  computed: {
    reasonList() {
      return '广告,时政,色情,裸露,辱骂,违法犯罪,欺诈,低俗,版权,泄露隐私（请打码）,帖子内容空洞无意义,重复发帖,请选择企业工商信息后重新发帖,不适合本平台收录,其他'.split(/,/)
    }
  },
  methods: {
    showModal(record) {
      this.visible = true;
      this.record = record;
      this.authRemark = "";
      this.title = "审核帖子：" + record.id;
      this.auditList = [];
      this.prizeTimes = 0;
      this.comments=''
      this.$post("/post/auditHistory", {postId: record.id}).then(res => {
        this.auditList = res.data || []
      })
    },
    addReason(item) {
      if (item === 'contactService') {
        this.authRemark = ' 尊敬的打工人，您好！我是诚信点评宣传组的志愿者，您之前发布的帖子富有代表性，为了展示给更多短视频观众，我们希望能够通过电话采访您。\n' +
            '        如果您同意，请您在收到此消息后联系在线客服。我作为志愿者有自己的工作安排，可能会在晚上或周末联系您，\n' +
            '        在此，我们对您的理解和支持表示衷心感谢。'
        return
      }
      this.authRemark = this.authRemark + (this.authRemark ? "，" : "") + item
    },
    addComment(item) {
      this.comments = this.comments + (this.comments ? "\r\n" : "") + item
    },
    randomPrizeTimes() {
      this.prizeTimes = Math.floor(Math.random() * (200 - 5) + 5);
    },
    handleOk(operate, isRecommend) {
      this.confirmLoading = true;
      if (operate == -1 && !this.authRemark) {
        this.confirmLoading = false;
        this.$message.error("请输入审核不通过的理由");
        return;
      }
      let data = {
        postId: this.record.id,
        passFlag: operate,
        auditRemark: this.authRemark,
        prizeTimes: this.prizeTimes,
        comments: this.comments,
        isTop: isRecommend ? 1 : undefined
      };

      this.$postJson('post/auditPost', data)
          .then((res) => {
            this.confirmLoading = false;
            this.visible = false;
            this.$emit("success", operate);
          })
          .catch((error) => {
            this.confirmLoading = false;
          });
    },
  },
};
</script>

<style lang="less" scoped>
.modal-btn {
  width: 100%;
  display: flex;
  justify-content: center;
}

.list-div {
  max-height: 200px;
  overflow-y: auto;
  padding-top: 10px;

  /deep/ .ant-timeline-item {
    padding-bottom: 10px;
  }

  /deep/ .ant-timeline-item-content {
    min-height: auto;
  }
}
</style>
