<template>
  <a-modal
      v-drag-modal
      :title="title"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :maskClosable="false"
      :width="1000"
      @ok="handleOk"
      @cancel="visible = false"
  >
    <a-form-model ref="ruleForm" :model="form" :rules="rules">
      <a-form-model-item
          label="标题"
          prop="title"
          v-bind="formItemLayout"
      >
        <a-input v-model.trim="form.title" v-decorator="['title']"
                 placeholder="请输入标题"/>
      </a-form-model-item>
      <a-form-model-item label="资源类型" v-bind="formItemLayout" prop="type">
        <a-radio-group v-model="form.type" v-decorator="['type']" :options="$DICT['resourceType']"/>
      </a-form-model-item>
      <a-form-model-item
          label="下载资源分类"
          prop="cateId"
          v-bind="formItemLayout"
      >
        <div style="display: flex;align-items: center">
          <a-select :options="cateList" v-model="form.cateId" v-decorator="['cateId']"
                    placeholder="请选择分类"></a-select>
        </div>
      </a-form-model-item>
      <a-form-model-item label="状态" v-bind="formItemLayout" prop="status">
        <a-radio-group v-model="form.status" v-decorator="['status']" :options="$DICT['validStatus']"/>
      </a-form-model-item>
      <a-form-model-item label="付费模式" v-bind="formItemLayout" prop="payMode">
        <a-radio-group v-model="form.payMode" v-decorator="['payMode']" :options="$DICT['resourcePayMode']"/>
      </a-form-model-item>
      <a-form-model-item
          label="金额"
          prop="payFee"
          v-bind="formItemLayout"
      >
        <a-input-number v-model.trim="form.payFee" v-decorator="['payFee']"
                        placeholder="请输入数量" :min="0" :step="0.1"/>
      </a-form-model-item>

      <a-form-model-item
          label="标签"
          prop="tags"
          v-bind="formItemLayout"
      >
        <a-input v-model.trim="form.tags" v-decorator="['tags']" placeholder="请输入标签（多个逗号分隔）"/>
      </a-form-model-item>

      <a-form-model-item label="目标类型" v-bind="formItemLayout" prop="targetMode">
        <a-radio-group v-model="form.targetMode" v-decorator="['targetMode']" :options="$DICT['resourceTargetMode']"/>
      </a-form-model-item>

      <a-form-model-item label="付费文本内容" v-bind="formItemLayout" prop="paidContent" v-show="form.targetMode==2">
        <a-textarea v-model="form.paidContent" :max-length="5000"/>
      </a-form-model-item>
      <a-form-model-item ref="doorHeader" label="付费资源文件" v-bind="formItemLayout" prop="fileUrl"
                         v-show="form.targetMode==1">
        <div class="clearfix">
          <a-upload
              name="file"
              list-type="text"
              class="avatar-uploader"
              accept="*"
              withCredentials
              :multiple="false"
              :data="{ bucketType: 'public', uploadType: 'image',prefix:'resource' }"
              :headers="headers"
              :file-list="fileList"
              action="admin/upload/uploadImage"
              :before-upload="beforeFileUpload"
              @preview="handlePreview($event,'file')"
              @change="(info) => handleImageChange(info,'file')"
          >
            <div v-if="fileList.length < 1"
                 style="display: flex;text-align: center;justify-content: center;flex-direction: column;padding:10px;border:1px solid #CCC;">

              <a-icon type="plus"/>
              <div class="ant-upload-text">上传</div>
            </div>
          </a-upload>
        </div>
      </a-form-model-item>

      <a-form-model-item ref="doorHeader" label="资源相关图片" v-bind="formItemLayout">
        <a-textarea v-model="form.images" v-decorator="['images']" style="min-height:30px;"
                    placeholder="可以直接输入图片路径（多个逗号分隔，首张图片作为封面）"/>
        <div class="clearfix">
          <a-upload
              name="file"
              list-type="picture-card"
              class="avatar-uploader"
              accept="image/jpg,image/jpeg,image/png,image/gif"
              withCredentials
              :multiple="true"
              :data="{ bucketType: 'public', uploadType: 'image' }"
              :headers="headers"
              :file-list="imageList"
              action="admin/upload/uploadImage"
              :before-upload="beforeUpload"
              @preview="handlePreview($event,'image')"
              @change="(info) => handleImageChange(info,'image')"
          >
            <div>
              <a-icon type="plus"/>
              <div class="ant-upload-text">上传</div>
            </div>
          </a-upload>
          <!--  -->
          <a-modal
              v-drag-modal
              title="图片预览"
              :visible="previewVisible"
              :footer="null"
              @cancel="previewVisible = false"
          >
            <img alt="example" style="width: 100%" :src="previewImage"/>
          </a-modal>
        </div>
      </a-form-model-item>

      <a-form-model-item label="描述内容" v-bind="formItemLayout">
        <tiny-mce-editor v-if="visible" ref="editor" v-model="form.content"
                         style="width: 100%"></tiny-mce-editor>
        <a-textarea v-model="form.content" :rows="8" allClear/>
      </a-form-model-item>

      <a-form-model-item label="备注说明" v-bind="formItemLayout" prop="contentDesc">
        <a-textarea v-model="form.remark" :max-length="255"/>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import TinyMceEditor from "@/components/Editor/TinyMceEditor";
import {getBase64OfPreview} from '@/utils/common.js';
import ImagePreviewModal from "../../post/postlist/ImagePreviewModal";
import PostAuditModal from "../../post/postlist/postAuditModal";

export default {
  name: "modifyDownloadResMModal",
  components: {TinyMceEditor},
  data() {
    return {
      formItemLayout: {
        labelCol: {span: 6},
        wrapperCol: {span: 15},
      },
      mode: 'new',
      orgCateList: [],
      title: "",
      previewImage: '',
      previewVisible: false,
      visible: false,
      confirmLoading: false,
      form: {},
      imageList: [],
      fileList: [],//文档文件
      rules: {
        title: [{required: true, message: '标题不能为空'}],
        cateId: [{required: true, message: '分类不能为空'}]
      }
    };
  },
  computed: {
    headers() {
      return {
        Authentication: this.$store.state.account.token,
      };
    },
    cateList() {
      return this.orgCateList.map(v => {
        return {
          value: v.cateId,
          label: v.cateName
        }
      })
    }
  },
  created() {
    //type: 类型（1--圈子分类，2--手册分类，3--评测分类,4--省钱宝分类，5--商品分类，6--行业分类，7--职业分类，8--下载资源分类）
    this.$postJson('/category/cateList', {pageSize: 100, type: 8, status: 1}).then(res => {
      this.orgCateList = ((res.data && res.data.records) || [])
    })
  },
  methods: {
    async showModal(record, mode) {
      this.visible = true;
      this.confirmLoading = false;
      this.mode = mode || 'new'

      const that = this;
      if (this.mode === 'update') {
        this.title = "修改下载资源：" + record.id;
        this.form = {
          ...record,
        };
        this.imageList = this.convertFileList(record.images)

        if (record.fileUrl) {
          this.fileList = [{
            type: "application/pdf",
            name: record.fileUrl,
            path: record.fileUrl,
            uid: record.fileUrl,
            status: "done",
            response: {data: record.fileUrl, code: 0},
          }];
        } else {
          this.fileList = []
        }
      } else {
        this.title = "新增下载资源";
        this.form = {
          status: 1,
          payMode: 0,
          targetMode: 1,
          type: 0
        };
        this.imageList = []
        this.fileList = []
      }
    },
    handleImageChange(info, type) {
      if (type === 'file') {
        this.fileList = info.fileList || [];
      } else {
        this.imageList = info.fileList || [];
      }
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      this.loading = false;
    },
    beforeUpload(file) {
      const isJpgOrPng =
          file.type === "image/jpeg" ||
          file.type === "image/png" ||
          file.type === "image/gif";
      if (!isJpgOrPng) {
        this.$message.error("只能支持JPEG/PNG/GIF图片!");
      }
      const isLt2M = file.size / 1024 / 1024 <= 3;
      if (!isLt2M) {
        this.$message.error("图片大小不能超过3MB!");
      }
      return isJpgOrPng && isLt2M;
    },
    beforeFileUpload(file) {
      let isValidType = true;
      // const isValidType = file.type === "application/pdf";
      // if (!isValidType) {
      //   this.$message.error("只能支持PDF格式文档!");
      // }
      const isValidSize = file.size / 1024 / 1024 <= 30;
      if (!isValidSize) {
        this.$message.error("文件大小不能超过30MB!");
      }
      return isValidType && isValidSize;
    },
    async handlePreview(file, type) {
      if (type === 'file') {
        window.open(file.url || file.response.data, "_blank")
      } else {
        if (!file.url && !file.preview && !file.thumbUrl) {
          file.preview = await getBase64OfPreview(file.originFileObj);
        }
        this.previewImage = file.url || file.preview || file.thumbUrl;
        this.previewVisible = true;
      }
    },
    convertFileList(list = []) {
      const that = this;
      if (!list) {
        return [];
      }
      if (typeof list === 'string') {
        list = list.split(/[,，、\r\n;]/g);
      }
      let array = [];
      list.forEach((v) => {
        if (!v) return;
        let path = that.$helper.getFullImgPath(v);
        array.push({
          type: "image/png",
          name: v,
          uid: v,
          status: "done",
          thumbUrl: path,
          response: {data: path, code: 0},
        });
      });

      return array;
    },
    handleOk() {
      this.$refs.ruleForm.validate((valid) => {
            if (valid) {
              if (this.imageList.length > 0) {
                this.form.images = this.imageList.map((item) => {
                  return item.response.data;
                }).join(',');
              } else {
                this.$message.warning('请输入或者上传至少一张图片（首张图片作为封面）');
                return;
                this.form.images = ''
              }
              if (this.form.targetMode == 1) {
                if (this.fileList.length > 0) {
                  this.form.fileUrl = this.fileList.map((item) => {
                    return item.response.data;
                  })[0];
                } else {
                  this.$message.warning('资源文件不能为空');
                  return;
                }
              } else {
                if (!this.form.paidContent) {
                  this.$message.warning('付费内容不能为空');
                  return;
                }
                this.form.fileUrl = "";
              }
              this.confirmLoading = true;

              this.$postJson("downloadRes/update", this.form).then((res) => {
                this.visible = false;
                this.confirmLoading = false;
                this.$message.success("保存成功");
                this.$emit("success");
              }).catch(() => {
                this.confirmLoading = false;
              });
            } else {
              this.confirmLoading = false;
            }
          }
      )
      ;
    },
  },
}
;
</script>
<style lang="less" scoped>
.ant-input-number {
  width: 100% !important;
}
</style>
